import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Splide from '@splidejs/splide';
// import Splidevideo from '@splidejs/splide-extension-video';
import LazyLoad from "vanilla-lazyload";

// --------------------------------------------------
// 🐌 General
// --------------------------------------------------

gsap.registerPlugin(ScrollTrigger);

gsap.defaults({
  ease: 'power1.inOut',
  duration: .5
})

// --------------------------------------------------
// 💤 Lazy Loading
// --------------------------------------------------

const lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
  elements_selector: "[data-lazy]",
  callback_loaded: (el) => {
    gsap.to(el, {
      autoAlpha: 1,
      duration: .5,
      ease: 'power1.inOut',
      onComplete: () => {
        el.dispatchEvent(new Event('lazyloaded'));
      }
    })
  }
});

// --------------------------------------------------
// Slide in menu on mobile
// --------------------------------------------------


let menuButton = document.getElementById('menu-button');
let menuModal = document.getElementById('menu-modal');
let closeButton = document.getElementById('menu-modal-close');

menuButton.addEventListener('click', () => {
  console.log('clicked')
  menuModal.classList.add('active');
  document.body.style.overflow = 'hidden';
});

closeButton.addEventListener('click', () => {
  menuModal.classList.remove('active');
  document.body.style.overflow = 'auto';
});


// --------------------------------------------------
// 🌐 Language switcher
// --------------------------------------------------

dropdownButton = document.getElementById("language-dropdown-button");
dropdownButton.addEventListener('click', () => {
  document.getElementById("language-dropdown").classList.toggle("show");
})

// Close the dropdown if the user clicks outside of it
window.onclick = function(event) {
if (!event.target.matches('.language-switcher__button')) {

  var dropdowns = document.getElementsByClassName("language-switcher__content");
  var i;
  for (i = 0; i < dropdowns.length; i++) {
    var openDropdown = dropdowns[i];
    if (openDropdown.classList.contains('show')) {
      openDropdown.classList.remove('show');
    }
  }
}
}

// --------------------------------------------------
// 🎨 Wrapping divs in DOM to add colour backgrounds
// --------------------------------------------------

//
// Adding cream gradient to logos module of Home template
//

// Get the correct <main> element
let home = document.querySelector('.home-template');
if (home) {
  // Find the .logo-animation-container div
  const logoContainer = document.querySelector('.logo-animation-container');

  // Find the previous div of .logo-animation-container
  const previousDiv = logoContainer.previousElementSibling;

  // Create a wrapper div with the required classes
  const wrapperDiv = document.createElement('div');
  wrapperDiv.classList.add('background-gradient', 'background-gradient--cream');

  // Wrap the .logo-animation-container and its previous div with the wrapper
  previousDiv.parentNode.insertBefore(wrapperDiv, previousDiv);
  wrapperDiv.appendChild(previousDiv);
  wrapperDiv.appendChild(logoContainer);
}

//
// Adding cream to bottom of About template
//

// Get the correct <main> element
let aboutMainContainer = document.querySelector('.about-template main');

if (aboutMainContainer) {
  // Get the last three child elements of the <main> element
  let lastThreeChildren = Array.from(aboutMainContainer.children).slice(-3);

  // Create a new wrapping <div>
  let aboutWrappingDiv = document.createElement('div');
  aboutWrappingDiv.classList.add('background-color', 'background-color--cream');

  // Append the last three children to the wrapping <div>
  lastThreeChildren.forEach(child => {
    aboutWrappingDiv.appendChild(child);
  });

  // Append the wrapping <div> to the <main> element
  aboutMainContainer.appendChild(aboutWrappingDiv);
}

//
// Memberships template
//

// Get the correct <main> element
let membershipsMainContainer = document.querySelector('.memberships-template main');

if (membershipsMainContainer) {  
  // Wrapping first four modules to be able to change bg colours on hover

  // Get the last three child elements of the <main> element
  let firstfourChildren = Array.from(membershipsMainContainer.children).slice(0, 4);

  // Create a new wrapping <div>
  let topMembershipsWrappingDiv = document.createElement('div');
  topMembershipsWrappingDiv.classList.add('background-hover-color');

  // Create a new div element with the class "fake-gradient"
  const newDiv = document.createElement("div");
  newDiv.classList.add("fake-gradient");

  // Append the new div to the wrapper div
  topMembershipsWrappingDiv.appendChild(newDiv);

  // Append the last three children to the wrapping <div>
  firstfourChildren.forEach(child => {
    topMembershipsWrappingDiv.appendChild(child);
  });

  // Append the wrapping <div> to the <main> element
  membershipsMainContainer.insertBefore(topMembershipsWrappingDiv, membershipsMainContainer.firstChild);

  // Adding cream gradient to bottom of Memberships template

  // Get the last three child elements of the <main> element
  let lastThreeChildren = Array.from(membershipsMainContainer.children).slice(-3);

  // Create a new wrapping <div>
  let bottomMembershipsWrappingDiv = document.createElement('div');
  bottomMembershipsWrappingDiv.classList.add('background-gradient', 'background-gradient--white-to-cream');

  // Append the last three children to the wrapping <div>
  lastThreeChildren.forEach(child => {
    bottomMembershipsWrappingDiv.appendChild(child);
  });

  // Append the wrapping <div> to the <main> element
  membershipsMainContainer.appendChild(bottomMembershipsWrappingDiv);

}

//
// Apply template
//

// Get the correct <form> element
let applyForm = document.querySelector('.apply-template form');
if (applyForm) {  
  // Wrapping first two divs to be able to change bg colours on hover

  // Get the last three child elements of the <main> element
  let firstTwoChildren = Array.from(applyForm.children).slice(0, 2);

  // Create a new wrapping <div>
  let applyFormChildrenWrappingDiv = document.createElement('div');
  applyFormChildrenWrappingDiv.classList.add('background-hover-color');

  // Create a new div element with the class "fake-gradient"
  const newDiv = document.createElement("div");
  newDiv.classList.add("fake-gradient");

  // Append the new div to the wrapper div
  applyFormChildrenWrappingDiv.appendChild(newDiv);

  // Append the last three children to the wrapping <div>
  firstTwoChildren.forEach(child => {
    applyFormChildrenWrappingDiv.appendChild(child);
  });

  // Append the wrapping <div> to the <main> element
  applyForm.insertBefore(applyFormChildrenWrappingDiv, applyForm.firstChild);

  // Change color background

  const colorDivs = applyFormChildrenWrappingDiv.querySelectorAll("[data-color]");

  colorDivs.forEach(colorDiv => {
    colorDiv.addEventListener("mouseover", () => {
      const bgColor = colorDiv.getAttribute("data-color");
      applyFormChildrenWrappingDiv.setAttribute("data-bg", bgColor);
    });
  });

}

// --------------------------------------------------
// 👩🏻‍🦳 Navbar background gradient scroll animation
// --------------------------------------------------

let navbarGradient = document.querySelector('.navbar-gradient')
let navbar = document.querySelector('.navbar')

window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
    navbarGradient.classList.add('active');
    if (navbar.classList.contains('light-navbar')) {
      navbar.classList.add('dark-navbar');
    }
  } else {
    navbarGradient.classList.remove('active');
    if (navbar.classList.contains('light-navbar')) {
      navbar.classList.remove('dark-navbar');
    }
  }
}

// --------------------------------------------------
// 🚁 Hover and change contents and background color
// --------------------------------------------------

let tab = document.querySelector('.tab-menu');
if (tab) {
  var tabNav = document.querySelectorAll('.tab-menu ul li div');
  var tabBody = document.querySelectorAll('.tab-body-content');
  var tabNavLength = tabNav.length;
  var tabBodyLength = tabBody.length;
  console.log(tabNavLength, tabBodyLength);

  //setting data attribute 
  for(var i = 0, j = 0; i < tabNavLength, j < tabBodyLength; i++, j++)
    {
      tabNav[i].setAttribute('data-id', 'tab' + i);
      tabBody[j].setAttribute('data-id', 'tab' + j);
    }

  // tab nav click function 
  for(var c = 0; c < tabNavLength; c++)
    {
    tabNav[c].addEventListener('mouseover', tabClicked);
    }

  // function call
  function tabClicked()
  {
    // getting the parent to avoid closing of other tabs in page 
    var tabNavParent = this.closest('.tab').querySelectorAll('.tab-menu ul li div');
    var tabBodyParent = this.closest('.tab').querySelectorAll('.tab-body-content');
    var tabNav = this.closest('.tab').querySelectorAll('.tab-menu ul li div');
    var tabBody = this.closest('.tab').querySelectorAll('.tab-body-content');
    var tabNavLength = tabNav.length;
    for(var d = 0; d < tabNavLength; d++)
    {
      // removing the added class at the begining on clicked div with out affecting other tabs
      tabNavParent[d].classList.remove('menu-active');
      tabBodyParent[d].classList.remove('show-tab');

      var navId = this.getAttribute('data-id');
      var bodyId = tabBodyParent[d].getAttribute('data-id');
      // checking data tab id
      if(navId == bodyId)
        {
          tabNavParent[d].classList.add('menu-active');
          tabBodyParent[d].classList.add('show-tab');
        }
      if(this.hasAttribute('data-color')) {
        const attributeValue = this.getAttribute('data-color');
        const el = document.querySelector(".background-hover-color");

        el.setAttribute('data-bg', attributeValue);
        }
    }
  }
}

// --------------------------------------------------
// 🔴 Scroll and hover dots animation
// --------------------------------------------------

// Function to handle the logic when the window is resized
function handleResize() {
  // Get the elements
  let columnA = document.querySelector('.scroll-hover-animation__column-a');
  let buttonsColumn = document.querySelector('.scroll-hover-animation__buttons-column');
  let columnB = document.querySelector('.scroll-hover-animation__column-b');

  if (columnA) {
    // Check screen width
    if (window.innerWidth <= 850) {
      // If screen width is less than or equal to 850px
      if (columnA.contains(buttonsColumn)) {
        // If buttonsColumn is a child of columnA, move it after columnB
        columnB.appendChild(buttonsColumn);
      }
    } else {
      // If screen width is greater than 850px
      if (!columnA.contains(buttonsColumn)) {
        // If buttonsColumn is not a child of columnA, move it back to its original position
        columnA.appendChild(buttonsColumn);
      }
    }
  }
}

// Attach the handleResize function to the window's resize event
window.addEventListener('resize', handleResize);

// Call handleResize initially to set the initial state
handleResize();

// Gsap animation
let mm = gsap.matchMedia();

let tl = gsap.timeline({
  scrollTrigger: {
  trigger: ".scroll-dots",
  endTrigger: ".scroll-hover-animation__buttons-column",
  pin: true,
  // markers: true,
  scrub: 2,
  start: "center center",
  end: "bottom center"
  }
});

tl.from(".scroll-dots__dot--first-layer", {
  opacity: 0,
  duration: 2.5,
  stagger: 0.3,
  ease: "back.out(4)"
});

tl.from(".scroll-dots__dot--second-layer", {
  opacity: 0,
  duration: 3,
  ease: "ease",
});

tl.to(".scroll-dots__dot", {
  x: 0,
  y: 0,
  backgroundColor: '#e8e7e4',
  duration: 3,
  ease: "ease",
});

tl.to(".scroll-dots__center-dot .white-radial-gradient", {
  opacity: 1,
  duration: 3,
}, "-=1.5");



mm.add("(min-width: 1025px) and (max-width: 1200px)", () => {
  tl.to(".scroll-dots__center-dot", {
    scale: 5,
    duration: 3,
    ease: "ease",
  }, "-=1.5");
});

mm.add("(min-width: 1201px) and (max-width: 1300px)", () => {
  tl.to(".scroll-dots__center-dot", {
    scale: 6,
    duration: 3,
    ease: "ease",
  }, "-=1.5");
});

mm.add("(min-width: 1301px)", () => {
  tl.to(".scroll-dots__center-dot", {
    scale: 7,
    duration: 3,
    ease: "ease",
  }, "-=1.5");
});

mm.add("(max-width: 850px)", () => {
  tl.to(".scroll-dots__center-dot", {
    scale: 4,
    duration: 3,
    ease: "ease",
  }, "-=1.5");
});

tl.to(".scroll-dots__text", {
  duration: 2.5,
  opacity: 1,
  ease: "ease",
}, "-=2");

mm.add("(max-width: 1200px)", () => {
  tl.to(".scroll-dots__text", {
    duration: 2.5,
    opacity: 1,
    ease: "ease",
  }, "-=1");
});

tl.to({}, {duration:2})

// --------------------------------------------------
// 🎠 Carousels
// --------------------------------------------------

// let carousel = document.querySelector('.splide');

// let splide = new Splide(carousel, {
//   type: 'loop',
//   speed: 600,
//   flickPower: 300,
//   arrows: true,
//   pagination: false,
//   perPage: 2.5,
//   perMove: 1,
//   focus: 'center',
//   width: '100%',
//   updateOnMove: true
// });

// // carousel.addEventListener('pause', () => {
// //   splide.Components.Autoplay.pause();
// // })

// // carousel.addEventListener('resume', () => {
// //   splide.Components.Autoplay.play();
// // })

// // Trigger lazyloading after splide has cloned the slides
// // splide.on('ready', () => {
// //   setupLazyLoading(carousel);
// // })

// splide.mount();


// Standard Splide
let elms = document.getElementsByClassName( 'testimonials__carousel' );

for ( var i = 0; i < elms.length; i++ ) {
  new Splide( elms[ i ], {
    type: 'loop',
    speed: 600,
    flickPower: 300,
    arrows: true,
    pagination: true,
    perPage: 1
  }).mount();
}

// Timeline Splide
let timelineCarousels = document.getElementsByClassName( 'timeline__carousel' );

for ( var i = 0; i < timelineCarousels.length; i++ ) {
  new Splide( timelineCarousels[ i ], {
    type: 'loop',
    speed: 600,
    flickPower: 300,
    arrows: true,
    pagination: false,
    perPage: 2.5,
    perMove: 1,
    focus: 'center',
    width: '100%',
    updateOnMove: true,
    breakpoints: {
      850: {
        perPage: 1
      },
    }
  }).mount();
}

// Auto height
// if(carousel.classList.contains('splide--auto-height')) {
//   const autoHeight = () => {
//     let activeSlide = splide.Components.Elements.slides[splide.index];
//     gsap.set(carousel.querySelector('.splide__list'), {
//       height: activeSlide.offsetHeight
//     })
//   }
//   autoHeight();
//   splide.on('move', (e) => {
//     autoHeight();
//   })
// }

// Counter container
// let carouselCounter = document.querySelector('.carousel-counter');
// if(carouselCounter) {

//   let counterContainer = document.querySelector('.carousel-counter__container');
//   let counterStatuses = document.querySelectorAll('.carousel-counter__status');

//   const updateCounter = () => {

//     counterStatuses.forEach((counterStatus) => {
//       counterStatus.innerHTML = splide.index + 1;
//     })
//   }

//   const positionCounterContainer = () => {
//     let activeSlide = splide.Components.Elements.slides[splide.index];
//     let activeImageWidth = activeSlide.querySelector('img').offsetWidth;
//     gsap.set(counterContainer, {
//       x: activeImageWidth
//     })
//   }
  
//   positionCounterContainer();

//   splide.on('move', (e) => {
//     updateCounter();
//     if(counterContainer) {
//       positionCounterContainer();
//     }
//   })
//   splide.on('resize', (e) => {
//     if(counterContainer) {
//       positionCounterContainer();
//     }
//   })

// }

// if(carousel.classList.contains('carousel--click-to-next')) {
//   let nextButtons = document.querySelectorAll('.carousel img');
//   if(nextButtons) {
//     nextButtons.forEach((item) => {
//       item.addEventListener( 'click', function() {
//         splide.go('>');
//       })
//     });
//   };
// }

// let carousels = document.querySelectorAll('.carousel');

// if (carousels.length) {

//   carousels.forEach(carousel => {
//     setupCarousel(carousel);
//   })

// }

// Testminonials With Media Splide
let testminonialsWithMediaCarousels = document.getElementsByClassName( 'testimonials-with-media__carousel' );

for ( var i = 0; i < testminonialsWithMediaCarousels.length; i++ ) {
  new Splide( testminonialsWithMediaCarousels[ i ], {
    pagination: true,
    perPage: 1,
    type: 'fade',
    rewind: true,
    speed: 600,
    flickPower: 300,
    arrows: true,
    breakpoints: {
      850: {
        arrows: false
      },
    }
  }).mount();
}

// --------------------------------------------------
// ⬇️ Custom select drop down for apply contact form
// --------------------------------------------------

var form = document.querySelector('.apply-form');
if (form) {

  a = document.querySelector('.select-selected')
  dropdown = document.querySelector('.select-items')
  /*for each option in the original select element,
  create a new DIV that will act as an option item:*/
  const clickableDivs = document.querySelectorAll('.new-role');
  var selectElement = document.getElementById('role');
  const selectSelected = document.querySelector('.select-selected');
  const formFields = document.querySelector('.form__inputs-wrapper')
  const hasHideClass = formFields.classList.contains('hidden-form-fields');
  const candidateRequirements = document.getElementById('candidate-requirements-container');
  // Add click event listeners to each clickable div
  clickableDivs.forEach(div => {
    div.addEventListener('click', function() {
      const targetValue = this.getAttribute('data-value');
      selectSelected.innerHTML = this.innerHTML;
      // Find the corresponding select option and set it as selected
      const targetOption = selectElement.querySelector(`option[value="${targetValue}"]`);
      if (targetOption) {
        targetOption.selected = true;
      }
      // If 'hide' class is present on form fields, remove it
      if (hasHideClass) {
        formFields.classList.remove('hidden-form-fields');
      }
      // Toggle the 'hide' class on the clicked div
      this.classList.toggle('hide');
      // Toggle the 'hide' class on all other divs
      clickableDivs.forEach(otherDiv => {
        if (otherDiv !== this) {
          otherDiv.classList.remove('hide');
        }
      });
      // Toggle the 'show' class on correct candidate requirements
      const correctRequirements = candidateRequirements.querySelector(`.candidate-requirements__radio-container[data-value="${targetValue}"]`);
      const allRequirements = document.querySelectorAll('.candidate-requirements__radio-container');
      console.log(correctRequirements);
      if (correctRequirements) {
        correctRequirements.classList.toggle('show');
        allRequirements.forEach(otherRequirements => {
          if (otherRequirements !== correctRequirements) {
            otherRequirements.classList.remove('show');
          }
        });
      }
    });
  });

  a.addEventListener("click", function(e) {
    /*when the select box is clicked, close any other select boxes,
    and open/close the current select box:*/
    e.stopPropagation();
    closeAllSelect(this);
    // this.nextSibling.classList.toggle("select-hide");
    dropdown.classList.toggle("select-hide");
    // this.classList.toggle("select-arrow-active");
    dropdown.classList.toggle("select-arrow-active");
  });

  function closeAllSelect(elmnt) {
    /*a function that will close all select boxes in the document,
    except the current select box:*/
    var x, y, i, xl, yl, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i)
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
    let applyFormChildrenWrappingDiv = document.querySelector('.background-hover-color');
    applyFormChildrenWrappingDiv.setAttribute("data-bg", "");
  }
  /*if the user clicks anywhere outside the select box,
  then close all select boxes:*/
  document.addEventListener("click", closeAllSelect);

}

// --------------------------------------------------
// 💌 Contact form
// --------------------------------------------------

var form = document.querySelector('.apply-form');
if (form) {
  
let messageText = document.querySelector('.message__text');
let message = document.querySelector('apply-form__message');
let fields = {};
form.querySelectorAll('[name]').forEach(function (field) {
    fields[field.name] = field;
});

// Displays all error messages and adds 'error' classes to the form fields with
// failed validation.
let handleError = function (response) {
    let errors = [];
    for (let key in response) {
        if (!response.hasOwnProperty(key)) continue;
        if (fields.hasOwnProperty(key)) fields[key].classList.add('error');
        Array.prototype.push.apply(errors, response[key]);
    }
    message.innerHTML = errors.join('<br>');
}

let onload = function (e) {
    if (e.target.status === 200) {
        message.innerHTML = messageText.innerHTML
    } else {
        handleError(JSON.parse(e.target.response));
    }
};

let submit = function (e) {
    e.preventDefault();
    let request = new XMLHttpRequest();
    request.open('POST', e.target.action);
    request.onload = onload;
    request.send(new FormData(e.target));
    // Remove all 'error' classes of a possible previously failed validation.
    for (let key in fields) {
        if (!fields.hasOwnProperty(key)) continue;
        fields[key].classList.remove('error');
    }
};
form.addEventListener('submit', submit);

}

// --------------------------------------------------
// 🪗 Accordion
// --------------------------------------------------


const accordions = document.querySelectorAll('.accordion');

const toggleAccordion = (accordionButton) => {

  // Close other accordion items
  accordions.forEach(otherAccordion => {
    const otherAccordionButton = otherAccordion.querySelector('button');
    if(otherAccordionButton !== accordionButton) {
      otherAccordionButton.setAttribute('aria-expanded', 'false');
    }
  });
  
  // Expand the accordion
  if(accordionButton.getAttribute('aria-expanded') !== 'true') {
    accordionButton.setAttribute('aria-expanded', 'true');
  }
  // Close the accordion
  else {
    accordionButton.setAttribute('aria-expanded', 'false');
  }

}

accordions.forEach(accordion => {
  const accordionButton = accordion.querySelector('button');
  accordionButton.addEventListener('click', () => {
    toggleAccordion(accordionButton);
  })
})

